<template>
    <div class="h5-container">
		<BACKH5 :title="$t('Home.CustomerService')" />
        <div class="main">
            <div class="list">
                <div v-for="(item,index) in serviceList" :key="index" class="list-item" @click="toLink(item.link)">
                    <div class="left">
                        <img class="icon" :src="item.pic" />
                        <div class="name">{{item.name}}</div>
                    </div>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
		components: {
		    BACKH5: () => import('@/components/BackH5.vue')
		},
        data() {
            return {
                serviceList: []
            }
        },
        created() {
			this.getServiceList()
        },
        methods: {
            getServiceList() {
                this.$api.setting_lang({
                    slug: 'kf_url'
                },300000).then(res => {
                    if(res.data) {
                        this.serviceList = JSON.parse(res.data)
                    }
                })
            },
            
            toLink(link) {
                if(link) {
                    location.href = link
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .main {
            width: 100%;
			box-sizing: border-box;
			padding: 1.6rem 1.2rem;
            
            .list {
                width: 100%;
                
                .list-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 5.2rem;
                    box-sizing: border-box;
                    padding: 0 1.2rem;
                    border-radius: .8rem;
                    background-color: #FFFFFF;
                    
                    &:not(:last-child) {
                        margin-bottom: 1.6rem;
                    }
                    
                    .left {
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                        
                        .icon {
                            min-width: 3rem;
                            max-width: 3rem;
                            height: 3rem;
                            margin-right: 1.2rem;
                        }
                        
                        .name {
                            width: 100%;
                            font-size: 1.6rem;
                            color: #000000;
                            word-wrap: break-word;
                        }
                    }
                    
                    .el-icon-arrow-right {
                        margin-left: 2rem;
                        font-size: 1.6rem;
                        font-weight: bold;
                        color: #999999;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
</style>